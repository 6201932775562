import React from "react";
import Footer from "../components/Footer";
import {useSearchParams} from "react-router-dom";

export default function Error() {
    const [searchParams] = useSearchParams();
    const msg = searchParams.get('error');

    return (
        <div id="wrapper">
            <div id="content-wrapper" className="d-flex flex-column">
                <div id="content">
                    <div className="container-fluid">
                        <div className="text-center">
                            <div className="error mx-auto" data-text="Error">Error</div>
                            <p className="lead text-gray-800 mb-5">{msg}</p>
                            <p className="text-gray-500 mb-0">It looks like you found a glitch in the matrix...</p>
                            <a href="/">&larr; Back to Dashboard</a>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
}
