import React, {useEffect} from "react";
import {Link} from "react-router-dom";
import useBodyClass from "../helpers/useBodyClass";
import {removeAuthUser} from "../helpers/authUser";

export default function Logout() {
    useEffect(() => {
        removeAuthUser();
    }, [])

    useBodyClass('bg-gradient-primary');

    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-xl-10 col-lg-12 col-md-9">
                    <div className="card o-hidden border-0 shadow-lg my-5">
                        <div className="card-body p-0">
                            <div className="row">
                                <div className="col-lg-6 d-none d-lg-block bg-login-image"></div>
                                <div className="col-lg-6">
                                    <div className="p-5">
                                        <div className="text-center">
                                            <h1 className="h4 text-gray-900 mb-4">You are Logged Out!</h1>
                                        </div>
                                        <hr />
                                        <div className="text-center">
                                            <Link className="small" to="/forgot" >Forgot Password?</Link>
                                        </div>
                                        <div className="text-center">
                                            <Link className="small" to="/" >Login again.</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
