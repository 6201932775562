import {getAuthUser} from "./authUser";

export function login(email, password) {
    return fetch("/api/users/authenticate", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            email,
            password
        })
    });
}

export function register(data) {
    const { firstName, lastName, email, password } = data;
    return fetch("/api/users/register", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            firstName, lastName, email, password
        })
    });
}

export function summary(code, callback) {
    return fetch(`/api/summary/${code}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    })
      .then(is404)
      .then((response) => response.json())
      .then(callback.good)
      .catch(callback.bad)
      .finally(callback.both);
}

export function getProjects(callback) {
    fetch("/api/projects", getOpts())
        .then(is404)
        .then((response) => response.json())
        .then(callback.good)
        .catch(callback.bad)
        .finally(callback.both);
}

export function getProject(id, callback) {
    return fetch(`/api/projects/${id}`, getOpts())
        .then(is404)
        .then((response) => response.json())
        .then(callback.good)
        .catch(callback.bad)
        .finally(callback.both);
}

export function getProjectByCode(code, callback) {
    return fetch(`/api/projects/code/${code}`, getOpts())
        .then(is404)
        .then((response) => response.json())
        .then(callback.good)
        .catch(callback.bad)
        .finally(callback.both);
}

export function addGroup(projectId, data, callback) {
    return fetch(`/api/projects/${projectId}/groups`, postOpts(JSON.stringify({
        name: data.name
    })))
        .then(is404)
        .then((response) => response.json())
        .then(callback.good)
        .catch(callback.bad)
        .finally(callback.both);
}

export function addPerson(projectId, data, callback) {
    return fetch(`/api/projects/${projectId}/people`, postOpts(JSON.stringify({
        name: data.name
    })))
        .then(is404)
        .then((response) => response.json())
        .then(callback.good)
        .catch(callback.bad)
        .finally(callback.both);
}

export function addExpense(projectId, data, callback) {
    return fetch(`/api/projects/${projectId}/expenses`, postOpts(JSON.stringify({
        name: data.name,
        payerId: data.payeeId,
        amount: data.amount
    })))
        .then(is404)
        .then((response) => response.json())
        .then(callback.good)
        .catch(callback.bad)
        .finally(callback.both);
}

export function deleteGroup(projectId, id, callback) {
    return fetch(`/api/projects/${projectId}/groups/${id}`, deleteOpts())
        .then(is404)
        .then((response) => response.json())
        .then(callback.good)
        .catch(callback.bad)
        .finally(callback.both);
}

export function deleteExpense(projectId, id, callback) {
    return fetch(`/api/projects/${projectId}/expenses/${id}`, deleteOpts())
        .then(is404)
        .then((response) => response.json())
        .then(callback.good)
        .catch(callback.bad)
        .finally(callback.both);
}

export function deletePerson(projectId, id, callback) {
    return fetch(`/api/projects/${projectId}/people/${id}`, deleteOpts())
        .then(is404)
        .then((response) => response.json())
        .then(callback.good)
        .catch(callback.bad)
        .finally(callback.both);
}

export function addProject(data, callback) {
    return fetch('/api/projects', postOpts(JSON.stringify({
        name: data.name
    })))
        .then(is404)
        .then((response) => response.json())
        .then(callback.good)
        .catch(callback.bad)
        .finally(callback.both);
}

export function updatePersonGroupId(projectId, personId, groupId, callback) {
    const data = (groupId === null) ? {} : { ProjectGroupId: groupId };
    return fetch(`/api/projects/${projectId}/people/${personId}`, putOpts(JSON.stringify(data)))
        .then(is404)
        .then((response) => response.json())
        .then(callback.good)
        .catch(callback.bad)
        .finally(callback.both);
}

export function deleteExpensePerson(projectId, id, callback) {
    return fetch(`/api/projects/${projectId}/dists/${id}`, deleteOpts())
        .then(is404)
        .then((response) => response.json())
        .then(callback.good)
        .catch(callback.bad)
        .finally(callback.both);
}

export function addExpensePerson(projectId, expenseId, personId, callback) {
    return fetch(`/api/projects/${projectId}/dists`, postOpts(JSON.stringify({
        ProjectExpenseId: expenseId,
        ProjectPersonId: personId
    })))
        .then(is404)
        .then((response) => response.json())
        .then(callback.good)
        .catch(callback.bad)
        .finally(callback.both);
}

// ======================= Helper functions

export class Status401Error extends Error {
    constructor(message) {
        super(message);
        this.name = "Status401Error";
    }
}
function is404(response) {
    if (response.status === 401) {
        throw new Status401Error('401');
    }
    if (!response.ok) {
        throw new Error(response.status);
    }
    return response;
}

function getOpts() {
    return {
        method: 'GET',
        headers: getHeaders()
    }
}

function deleteOpts() {
    return {
        method: 'DELETE',
        headers: getHeaders()
    }
}

function postOpts(body) {
    return {
        method: 'POST',
        body,
        headers: getHeaders()
    }
}

function putOpts(body) {
    return {
        method: 'PUT',
        body,
        headers: getHeaders()
    }
}

function getHeaders() {
    const user = getAuthUser();
    return {
        'Authorization': `Bearer ${user.token}`,
        'Content-Type': 'application/json'
    };
}
