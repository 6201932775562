import {Button} from "react-bootstrap";
import React from "react";
import {compareName} from "../../helpers/misc";
import DisplayIf from "../../components/DisplayIf";

export function ProjectGroupsCard(props) {
    const { project, deleteGroup,selectGroup,selectedGroup, onClickGroupPeople, handleAddGroupButton } = props;

    const GroupMemberCount = (props) => {
        const { id } = props;
        const members = project.ProjectPeople.filter(pp => pp.ProjectGroupId === id);

        if (members.length === 0) {
            return (
                <div className="text-danger"><i className="fas fa-fw fa-exclamation-triangle"></i> 0 people</div>
            );
        } else {
            const name = members.length > 1 ? 'people' : 'person';
            return (
                <div>{members.length} {name}</div>
            );
        }
    };

    const GroupPeople = (props) => {
        return project.ProjectPeople.sort(compareName)
            .filter(pp => pp.ProjectGroupId == null || pp.ProjectGroupId === selectedGroup)
            .map(pp => {
                const distType = (pp.ProjectGroupId === selectedGroup) ? 'success' : 'outline-success';

                return (
                    <Button className="expense-people-button" onClick={() => onClickGroupPeople(selectedGroup, pp)} variant={distType} key={pp.id}>{pp.name}</Button>
                );
           });
    };

    const GroupRows = () => {
        if (project.ProjectGroups.length === 0) {
            return (
                <tr><td colSpan="1">No Groups</td></tr>
            );
        } else {
            return project.ProjectGroups.sort(compareName).map(p => {
                const trClass = p.id === selectedGroup ? 'table-primary' : '';
                return (
                    <React.Fragment key={p.id}>
                        <tr onClick={() => selectGroup(p.id)} className={trClass}>
                            <td>
                                <Button variant="danger" size="sm" onClick={() => deleteGroup(p.id)}><i className="fas fa-fw fa-trash"></i></Button> {p.name}
                            </td>
                            <td><GroupMemberCount id={p.id} /></td>
                        </tr>
                        <DisplayIf cond={p.id === selectedGroup}>
                            <tr>
                                <td colSpan="2">
                                    <GroupPeople />
                                </td>
                            </tr>
                        </DisplayIf>
                    </React.Fragment>
                );
            });
        }
    };

    return (
        <div className="col-xl-6 col-lg-6">
            <div className="card shadow mb-4">
                <div
                    className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h6 className="m-0 font-weight-bold text-primary">Groups</h6>
                    <Button onClick={handleAddGroupButton}><i className="fas fa-fw fa-plus"></i></Button>
                </div>
                <div className="card-body">
                    <table className="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th className="table-fit">Members</th>
                        </tr>
                        </thead>
                        <tbody>
                        <GroupRows />
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}
