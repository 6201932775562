import React, {useState, useEffect} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import DisplayIf from "../components/DisplayIf";
import Spinner from "../components/Spinner";
import AddPersonModal from "../components/AddPersonModal";
import * as CRUD from '../helpers/crud';
import AddExpenseModal from "../components/AddExpenseModal";
import AddGroupModal from "../components/AddGroupModal";
import {ProjectGroupsCard} from "./project/ProjectGroupsCard";
import {ProjectPeopleCard} from "./project/ProjectPeopleCard";
import {ProjectExpenseCard} from "./project/ProjectExpenseCard";
import {Tab, Tabs} from "react-bootstrap";
import {restBad, valid} from "../helpers/misc";
import {ProjectShare} from "./project/ProjectShare";
import {ProjectSummary} from "./project/ProjectSummary";

function loadProject(setLoading, id, setProject, navigate) {
    setLoading(true);
    CRUD.getProject(id, { good: setProject, bad: (error) => restBad(error, navigate), both: setLoading(false) });
}

const emptyProject = {
    name: '',
    ProjectExpenses: [],
    ProjectPeople: [],
    ProjectGroups: [],
    summary: {
        name: '',
        details: [],
        summary: [],
        names: []
    }
};

export default function Project() {
    const params = useParams();
    const { id } = params;

    const [loading, setLoading] = useState(false);
    const [project, setProject] = useState(emptyProject);
    const [selectedExpense, setSelectedExpense] = useState('');
    const [selectedGroup, setSelectedGroup] = useState('');
    const [addPersonModal, setAddPersonModal] = useState(false);
    const [addExpenseModal, setAddExpenseModal] = useState(false);
    const [addGroupModal, setAddGroupModal] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        loadProject(setLoading, id, setProject, navigate);
    }, [id, navigate]);

    const loadProjectWithParms = () => {
        loadProject(setLoading, id, setProject, navigate);
    };

    const handleAddExpenseButton = () => {
        setAddExpenseModal(true);
    };

    const handleAddPersonButton = () => {
        setAddPersonModal(true);
    };

    const handleAddGroupButton = () => {
        setAddGroupModal(true);
    };

    const selectExpense = (id) => {
        setSelectedExpense(id);
    };

    const selectGroup = (id) => {
        setSelectedGroup(id);
    };

    const addExpense = (data) => {
        if (!('error' in data)) {
            setLoading(true);
            loadProjectWithParms();
        } else {
            restBad(data.error, navigate);
        }
        setAddExpenseModal(false);
    };

    const addGroup = (data) => {
        if (!('error' in data)) {
            setLoading(true);
            loadProjectWithParms();
        } else {
            restBad(data.error, navigate);
        }
        setAddGroupModal(false);
    };

    const addPerson = (data) => {
        if (!('error' in data)) {
            setLoading(true);
            loadProjectWithParms();
        } else {
            restBad(data.error, navigate);
        }
        setAddPersonModal(false);
    };

    const onClickGroupPeople = (groupId, person) => {
        if (person.ProjectGroupId === groupId) {
            CRUD.updatePersonGroupId(id, person.id, null, { good: loadProjectWithParms, bad: (error) => restBad(error, navigate), both: () => {} });
        } else {
            CRUD.updatePersonGroupId(id, person.id, groupId, { good: loadProjectWithParms, bad: (error) => restBad(error, navigate), both: () => {} });
        }
    };

    const onClickExpensePeople = (distId, personId) => {
        if (distId) {
            CRUD.deleteExpensePerson(id, distId, { good: loadProjectWithParms, bad: (error) => restBad(error, navigate), both: () => {}});
        } else {
            CRUD.addExpensePerson(id, selectedExpense, personId, { good: loadProjectWithParms, bad: (error) => restBad(error, navigate), both: () => {}});
        }
    };

    const deleteExpense = (id) => {
        setLoading(true);
        CRUD.deleteExpense(project.id, id, { good: loadProjectWithParms, bad: (error) => restBad(error, navigate), both: () => {}});
    };

    const deleteGroup = (id) => {
        setLoading(true);
        CRUD.deleteGroup(project.id, id, { good: loadProjectWithParms, bad: (error) => restBad(error, navigate), both: () => {}});
    };

    const deletePerson = (id) => {
        setLoading(true);
        CRUD.deletePerson(project.id, id, { good: loadProjectWithParms, bad: (error) => restBad(error, navigate), both: () => {}});
    }

    const isAddExpenseEnabled = (project.ProjectPeople.length > 0);
    const isProjectValid = (valid(project));

    return (
        <div id="wrapper">
            <Spinner cond={loading} />
            <DisplayIf cond={addPersonModal}>
                <AddPersonModal project={project} addPerson={addPerson} />
            </DisplayIf>
            <DisplayIf cond={addExpenseModal}>
                <AddExpenseModal project={project} addExpense={addExpense} />
            </DisplayIf>
            <DisplayIf cond={addGroupModal}>
                <AddGroupModal project={project} addGroup={addGroup} />
            </DisplayIf>
            <div id="content-wrapper" className="d-flex flex-column">
                <div id="content">
                    <NavBar />

                    <div className="container-fluid">
                        <div className="d-sm-flex align-items-center justify-content-between mb-4">
                            <h1 className="h3 mb-0 text-gray-800">{project.name}</h1>
                            <Link className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm" to="/">
                                <i className="fas fa-arrow-left"></i> Back to Projects
                            </Link>
                        </div>

                        <Tabs defaultActiveKey="edit" className="mb-3">
                            <Tab eventKey="edit" title="Edit">
                                <div className="row">
                                    <ProjectExpenseCard project={project} selectedExpense={selectedExpense} handleAddExpenseButton={handleAddExpenseButton}
                                                        onClickExpensePeople={onClickExpensePeople} isAddExpenseEnabled={isAddExpenseEnabled}
                                                        deleteExpense={deleteExpense} selectExpense={selectExpense} />
                                </div>
                                <div className="row">
                                    <ProjectPeopleCard project={project} handleAddPersonButton={handleAddPersonButton} deletePerson={deletePerson} />
                                    <ProjectGroupsCard project={project} deleteGroup={deleteGroup} selectGroup={selectGroup} selectedGroup={selectedGroup} onClickGroupPeople={onClickGroupPeople} handleAddGroupButton={handleAddGroupButton} />
                                </div>
                            </Tab>
                            <Tab eventKey="summarize" title="Summarize" disabled={! isProjectValid}>
                                <ProjectSummary summary={project.summary} />
                            </Tab>
                            <Tab eventKey="share" title="Share" disabled={! isProjectValid}>
                                <ProjectShare project={project} />
                            </Tab>
                        </Tabs>
                    </div>
                </div>

                <Footer />
            </div>
        </div>
    );
}
