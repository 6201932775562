import React from "react";
import {Dropdown} from "react-bootstrap";
import DisplayIf from "./DisplayIf";
import {getAuthUser} from "../helpers/authUser";
import {useNavigate} from "react-router-dom";

export default function NavBar() {
    const user = getAuthUser();
    const navigate = useNavigate();

    // The forwardRef is important!! Dropdown needs access to the DOM node in order to position the Menu
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <a className="nav-link dropdown-toggle" id="userDropdown" ref={ref} role="button"
           aria-haspopup="true" aria-expanded="false" onClick={(e) => {
                e.preventDefault();
                onClick(e);
        }}>
            {children}
        </a>
    ));

    // forwardRef again here!! Dropdown needs access to the DOM of the Menu to measure it
    const CustomMenu = React.forwardRef(
        ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
            return (
                <div style={style} aria-labelledby={labeledBy} className={className} ref={ref}>
                    {React.Children.toArray(children)}
                </div>
            );
        },
    );

    const userDropdown = (e) => {
        const which = e.target.getAttribute('data-id');
        if (which === "logout") {
            navigate('/logout');
        } else if (which === "admin") {
            navigate('/admin');
        } else if (which === "profile") {
            navigate('/profile');
        }
    }

    return (
        <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
            <ul className="navbar-nav ml-auto">
                <div className="topbar-divider d-none d-sm-block"></div>

                <Dropdown as="li" className="nav-item dropdown no-arrow">
                    <Dropdown.Toggle as={CustomToggle} id="user-dropdown">
                        <span className="mr-2 d-none d-lg-inline text-gray-600 small">{user.firstName} {user.lastName}</span>
                        <img className="img-profile rounded-circle" alt="" src="/img/undraw_profile.svg"/>
                    </Dropdown.Toggle>

                    <Dropdown.Menu as={CustomMenu} className="dropdown-menu dropdown-menu-right shadow animated--grow-in">
                        <DisplayIf cond={user.admin}>
                            <Dropdown.Item onClick={userDropdown} data-id="admin">
                                <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                                Administration
                            </Dropdown.Item>
                        </DisplayIf>
                        <Dropdown.Item onClick={userDropdown} data-id="profile">
                            <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                            Profile
                        </Dropdown.Item>
                        <div className="dropdown-divider"></div>
                        <Dropdown.Item onClick={userDropdown} data-id="logout">
                            <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                            Logout
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </ul>
        </nav>
    );
}
