export default function DisplayIf(props) {
    const { cond, children } = props;

    if (cond) {
        return (
            <>
                {children}
            </>
        );
    } else {
        return <></>;
    }
}
