import React, {useState} from "react";
import {Button, Modal} from "react-bootstrap";
import {useForm} from "react-hook-form";
import Spinner from "../components/Spinner";
import * as CRUD from "../helpers/crud";

export default function AddGroupModal(props) {
    const { project, addGroup } = props;
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [ loading, setLoading ] = useState(false);

    const handleError = (errors) => {}

    const handleGroup = (data) => {
        setLoading(true);

        CRUD.addGroup(project.id, data,{ good: (group) => addGroup({ group }), bad: (error) => addGroup({ error}), both: () => setLoading(false) });
    };

    const handleClose = () => addGroup({ });

    const options = {
        name: {
            required: "Name is required",
            validate: (value) => {
                const x = project.ProjectGroups.find(p => p.name.toLowerCase() === value.toLowerCase());
                return !!(!x);
            }
        }
    };

    return (
        <Modal show={true} onHide={handleClose}>
            <Spinner cond={loading} />
            <form className="person" onSubmit={handleSubmit(handleGroup, handleError)}>
                <Modal.Header>
                    <Modal.Title>Add Group</Modal.Title>
                </Modal.Header>
                <div className="form-group">
                    <input type="name" className="form-control form-control-user" id="addGroupNAme" placeholder="Name"
                           {...register('name', options.name) } />
                    <small className="text-danger">
                        {errors?.name && errors.name.message}
                        {errors?.name && errors.name.type === "validate" && (
                            <>The group name must be unique</>
                        )}
                    </small>
                </div>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" type="submit">
                        Save
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    );
}
