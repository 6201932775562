import React, {useState} from "react";
import {Link, useNavigate, useLocation} from "react-router-dom";
import { useForm } from "react-hook-form";
import useBodyClass from "../helpers/useBodyClass";
import {setAuthUser} from "../helpers/authUser";
import Spinner from "../components/Spinner";
import * as CRUD from '../helpers/crud';

export default function Login() {
    const location = useLocation();
    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [ loading, setLoading ] = useState(false);

    const handleError = (errors) => {};

    useBodyClass('bg-gradient-primary');

    const handleLogin = (data) => {
        setLoading(true);

        CRUD.login(data.email, data.password)
            .then(res => res.json())
            .then((user) => {
                    setAuthUser(user);

                    const from = location.state?.from?.pathname || "/";
                    navigate(from, { replace: true });
                },
                (error) => {
                    console.log(error);
                }
            )
            .finally(() => setLoading(false));
    };

    const loginOptions = {
        email: { required: "Email is required" },
        password: { required: "Password is required" }
    };

    return (
        <div className="container">
            <Spinner cond={loading} />
            <div className="row justify-content-center">
                <div className="col-xl-10 col-lg-12 col-md-9">
                    <div className="card o-hidden border-0 shadow-lg my-5">
                        <div className="card-body p-0">
                            <div className="row">
                                <div className="col-lg-6 d-none d-lg-block bg-login-image"></div>
                                <div className="col-lg-6">
                                    <div className="p-5">
                                        <div className="text-center">
                                            <h1 className="h4 text-gray-900 mb-4">Welcome Back!</h1>
                                        </div>
                                        <form className="user" onSubmit={handleSubmit(handleLogin, handleError)}>
                                            <div className="form-group">
                                                <input type="email" className="form-control form-control-user"
                                                       id="formEmail" aria-describedby="emailHelp"
                                                       placeholder="Enter Email Address..."
                                                       {...register('email', loginOptions.email) } />
                                                <small className="text-danger">
                                                    {errors?.email && errors.email.message}
                                                </small>
                                            </div>
                                            <div className="form-group">
                                                <input type="password" className="form-control form-control-user"
                                                       id="formPassword" placeholder="Password"
                                                       {...register('password', loginOptions.password)} />
                                                <small className="text-danger">
                                                    {errors?.password && errors.password.message}
                                                </small>
                                            </div>
                                            <button className="btn btn-primary btn-user btn-block">Login</button>
                                            <hr />
                                        </form>
                                        <hr />
                                        <div className="text-center">
                                            <Link className="small" to="/forgot" >Forgot Password?</Link>
                                        </div>
                                        <div className="text-center">
                                            <Link className="small" to="/register" >Create an Account!</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
