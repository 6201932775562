import React, {useState, useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import Footer from "../components/Footer";
import Spinner from "../components/Spinner";
import {ProjectSummary} from "./project/ProjectSummary";
import * as CRUD from "../helpers/crud";
import {restBad} from "../helpers/misc";

export default function Share() {
    const params = useParams();
    const { code } = params;

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [summary, setSummary] = useState({ name: '', names: [], details: [], summary: [] });

    useEffect(() => {
        setLoading(true);
        CRUD.summary(code, { good: setSummary, bad: (error) => restBad(error, navigate), both: setLoading(false) });
    }, []);

    return (
        <div id="wrapper">
            <Spinner cond={loading} />
            <div id="content-wrapper" className="d-flex flex-column">
                <div id="content">
                    <div className="container-fluid">
                        <div className="d-sm-flex align-items-center justify-content-between mb-4">
                            <h1 className="h3 mb-0 text-gray-800">{summary.name}</h1>
                        </div>

                        <ProjectSummary summary={summary} />
                    </div>
                </div>

                <Footer />
            </div>
        </div>
    );
}
