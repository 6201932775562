import React, {useState, useEffect} from "react";
import {Link, useNavigate} from "react-router-dom";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import Spinner from "../components/Spinner";
import DisplayIf from "../components/DisplayIf";
import AddProjectModal from "../components/AddProjectModal";
import * as CRUD from '../helpers/crud';
import {Button} from "react-bootstrap";
import {restBad} from "../helpers/misc";

function loadProjects (setProjects, setLoading, navigate) {
    setLoading(true);
    CRUD.getProjects({ good: setProjects, bad: (error) => restBad(error, navigate), both: () => setLoading(false) });
};

export default function Home() {
    const [loading, setLoading] = useState(false);
    const [projects, setProjects] = useState([]);
    const [addProjectModal, setAddProjectModal] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        loadProjects(setProjects, setLoading, navigate);
    },[navigate]);

    const addProject = () => {
        setAddProjectModal(false);
        loadProjects(setProjects, setLoading, navigate);
    };

    const onClickAddProject = () => {
        setAddProjectModal(true);
    };

    const Rows = () => {
        return projects.map(p => <tr key={p.id}><td><Link to={`/project/${p.id}`}>{p.name}</Link></td></tr>)
    };

    return (
        <div id="wrapper">
            <DisplayIf cond={addProjectModal}>
                <AddProjectModal addProject={addProject} />
            </DisplayIf>
            <Spinner cond={loading} />
            <div id="content-wrapper" className="d-flex flex-column">
                <div id="content">
                    <NavBar />

                    <div className="container-fluid">
                        <div className="d-sm-flex align-items-center justify-content-between mb-4">
                            <h1 className="h3 mb-0 text-gray-800">Dashboard</h1>
                            <Button className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm" onClick={onClickAddProject}>
                                <i className="fas fa-download fa-sm text-white-50"></i> Add Project
                            </Button>
                        </div>

                        <div className="row">
                            <div className="col-xl-12 col-lg-12">
                                <div className="card shadow mb-4">
                                    <div
                                        className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                        <h6 className="m-0 font-weight-bold text-primary">Projects</h6>
                                    </div>
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <table className="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
                                                <thead>
                                                <tr>
                                                    <th>Name</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                    <Rows />
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        </div>
    );
}
