import React from "react";
import currency from 'currency.js';

export function ProjectSummary(props) {
    const { summary } = props;

    const DetailRows = () => {
        const rows = summary.details.map(d => {
            const amounts = d.amounts.map((a, i) => <td className="text-right" key={i}>{currency(a).format()}</td>);
            const rowClass = d.total ? "table-primary" : "";
            return (
                <tr className={rowClass} key={d.name}>
                    <td>{d.name}</td>
                    {amounts}
                </tr>
            )
        });

        return (
            <>
                {rows}
            </>
        );
    };

    const TransactionRows = () => {
        const trans = summary.summary.map((s,i) => <tr key={i}><td><span className="transaction">{s}</span></td></tr>);
        return (
            <tbody>
                {trans}
            </tbody>
        );
    };

    const NameHeaders = () => {
        const ths = summary.names.map((pp,i) => <th key={i} className="table-fit">{pp}</th> );

        return (
            <>
                {ths}
            </>
        );
    };

    return (
        <>
            <div className="row">
                <div className="col-xl-12 col-lg-12">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h6 className="m-0 font-weight-bold text-primary">Transactions</h6>
                        </div>
                        <div className="card-body">
                            <table className="table table-bordered" width="100%" cellSpacing="0">
                                <TransactionRows />
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xl-12 col-lg-12">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h6 className="m-0 font-weight-bold text-primary">Summary</h6>
                        </div>
                        <div className="card-body">
                            <table className="table table-bordered table-responsive" width="100%" cellSpacing="0">
                                <thead>
                                <tr>
                                    <th>Expense</th>
                                    <NameHeaders />
                                </tr>
                                </thead>
                                <tbody>
                                    <DetailRows />
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
