import React, {useState} from "react";
import {Button} from "react-bootstrap";
import DisplayIf from "../../components/DisplayIf";

export function ProjectShare(props) {
    const { project } = props;

    const [error, setError] = useState(false);
    const [done, setDone] = useState(false);

    const url = `${process.env.REACT_APP_HOSTNAME}/share/${project.code}`;

    const copy = () => {
        navigator.clipboard.writeText(url)
            .then(() => {
                setDone(true);
            })
            .catch((err) => {
                setError(true);
            });
    };

    return (
        <>
            <div className="row">
                <div className="col-xl-12 col-lg-12">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h6 className="m-0 font-weight-bold text-primary">Share</h6>
                        </div>
                        <div className="card-body">
                            <p>You can share this project using this URL:</p>
                            <p className="shareurl">{url}</p>
                            <Button onClick={copy}>Copy to Clipboard</Button>
                            <DisplayIf cond={done}>
                                Copied to clipboard!
                            </DisplayIf>
                            <DisplayIf cond={error}>
                                Error copying to clipboard.
                            </DisplayIf>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
