function getAuthUser() {
    const userStr = localStorage.getItem('user');
    if (userStr) {
        try {
            const user = JSON.parse(userStr);
            if ('token' in user) {
                return user;
            }
        } catch(e) {
            // Can't parse it, it's not valid
        }
    }
    return null;
}

function setAuthUser(user) {
    localStorage.setItem('user', JSON.stringify(user));
}

function removeAuthUser() {
    localStorage.removeItem('user');
}

export { getAuthUser, setAuthUser, removeAuthUser };
