import React from "react";

export default function Spinner(props) {
    const { cond } = props;

    if (cond) {
        return (
            <div className="d-flex justify-content-center loading">
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    } else {
        return <></>;
    }
}
