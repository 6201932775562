import {Button} from "react-bootstrap";
import React from "react";
import {compareName} from "../../helpers/misc";

export function ProjectPeopleCard(props) {
    const { project, handleAddPersonButton, deletePerson } = props;

    const PeopleRows = () => {
        if (project.ProjectPeople.length === 0) {
            return (
                <tr><td colSpan="1">No People</td></tr>
            );
        } else {
            return project.ProjectPeople.sort(compareName).map(p => {
                return (
                    <tr key={p.id}>
                        <td><Button variant="danger" size="sm" onClick={() => deletePerson(p.id)}><i className="fas fa-fw fa-trash"></i></Button> {p.name}</td>
                    </tr>
                );
            });
        }
    };

    return (
        <div className="col-xl-6 col-lg-6">
            <div className="card shadow mb-4">
                <div
                    className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h6 className="m-0 font-weight-bold text-primary">People</h6>
                    <Button onClick={handleAddPersonButton}><i className="fas fa-fw fa-plus"></i></Button>
                </div>
                <div className="card-body">
                    <table className="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
                        <thead>
                        <tr>
                            <th>Name</th>
                        </tr>
                        </thead>
                        <tbody>
                        <PeopleRows />
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}
