import React, {useState} from "react";
import {Button, Form, Modal} from "react-bootstrap";
import {useForm} from "react-hook-form";
import Spinner from "../components/Spinner";
import * as CRUD from "../helpers/crud";

export default function AddExpenseModal(props) {
    const { project, addExpense } = props;
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [ loading, setLoading ] = useState(false);

    const handleError = (errors) => {}

    const handleExpense = (data) => {
        setLoading(true);

        CRUD.addExpense(project.id, data, { good: (expense) => addExpense({ expense}), bad: (error) => addExpense({ error }), both: () => { setLoading(false); } });
    };

    const handleClose = () => addExpense({ });

    const options = {
        name: { required: "Name is required" },
        amount: { required: "Amount is required" },
        payeeId: { required: "Payee is required" }
    };

    const PayeeOptions = () => {
        return project.ProjectPeople.map(pp => (<option key={pp.id} value={pp.id}>{pp.name}</option>));
    };

    return (
        <Modal show={true} onHide={handleClose}>
            <Spinner cond={loading} />
            <form onSubmit={handleSubmit(handleExpense, handleError)}>
                <Modal.Header>
                    <Modal.Title>Add Expense</Modal.Title>
                </Modal.Header>
                <div className="form-group">
                    <input type="name" className="form-control form-control-user" id="addExpenseName" placeholder="Expense Name"
                           {...register('name', options.name) } />
                    <small className="text-danger">
                        {errors?.name && errors.name.message}
                    </small>
                </div>
                <div className="form-group">
                    <input type="number" min="0.01" step="0.01" className="form-control form-control-user" id="addExpenseAmount" placeholder="Amount"
                           {...register('amount', options.amount) } />
                    <small className="text-danger">
                        {errors?.amount && errors.amount.message}
                    </small>
                </div>
                <div className="form-group">
                    <Form.Label htmlFor="payee">Payee</Form.Label>
                    <Form.Select id="payee" className="form-control" {...register('payeeId', options.payeeId) }>
                        <PayeeOptions />
                    </Form.Select>
                </div>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" type="submit">
                        Save Changes
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    );
}
