import React from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import {Link} from "react-router-dom";
import {getAuthUser} from "../helpers/authUser";

export default function Profile() {
    const user = getAuthUser();

    return (
        <div id="wrapper">
            <div id="content-wrapper" className="d-flex flex-column">
                <div id="content">
                    <NavBar />

                    <div className="container-fluid">
                        <div className="d-sm-flex align-items-center justify-content-between mb-4">
                            <h1 className="h3 mb-0 text-gray-800">Profile for {user.firstName}</h1>
                            <Link className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm" to="/">
                                <i className="fas fa-arrow-left"></i> Back to Projects
                            </Link>
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        </div>
    );
}
