import {Button} from "react-bootstrap";
import React from "react";
import {compareName} from "../../helpers/misc";
import DisplayIf from "../../components/DisplayIf";

export function ProjectExpenseCard(props) {
    const { project, selectedExpense, selectExpense, handleAddExpenseButton, onClickExpensePeople, isAddExpenseEnabled, deleteExpense } = props;

    const ConsumerCount = (props) => {
        const { expense } = props;
        const expensePerson = project.ProjectExpenseDists.filter(ppd => ppd.ProjectExpenseId === expense.id);
        if (expensePerson.length === 0) {
            return (
                <div className="text-danger"><i className="fas fa-fw fa-exclamation-triangle"></i> 0 people</div>
            );
        } else {
            const name = expensePerson.length > 1 ? 'people' : 'person';
            return (
                <div>{expensePerson.length} {name}</div>
            );
        }
    };

    const ExpensePeople = () => {
        const expensePerson = project.ProjectExpenseDists.filter(ppd => ppd.ProjectExpenseId === selectedExpense);

        return project.ProjectPeople.sort(compareName).map(pp => {
            const person = expensePerson.find(ep => ep.ProjectPersonId === pp.id);
            const distType = person ? 'success' : 'outline-success';
            const personId = person?.id;

            return (
                <Button className="expense-people-button" onClick={() => onClickExpensePeople(personId, pp.id)} variant={distType} key={pp.id}>{pp.name}</Button>
            )
        });
    };

    const ExpenseRows = () => {
        if (project.ProjectExpenses.length === 0) {
            return (
                <tr><td colSpan="4">No Expenses</td></tr>
            );
        } else {
            return project.ProjectExpenses.sort(compareName).map(p => {
                const payee = project.ProjectPeople.filter(pp => pp.id === p.payerId);
                const trClass = p.id === selectedExpense ? 'table-primary' : '';
                return (
                    <React.Fragment key={p.id}>
                        <tr onClick={() => selectExpense(p.id)} className={trClass}>
                            <td>
                                <Button variant="danger" size="sm" onClick={() => deleteExpense(p.id)}><i className="fas fa-fw fa-trash"></i></Button> {p.name}
                            </td>
                            <td className="table-fit">{payee[0].name}</td>
                            <td className="table-fit"><ConsumerCount expense={p} /></td>
                            <td className="table-fit"><div className="text-right">{p.amount}</div></td>
                        </tr>
                        <DisplayIf cond={p.id === selectedExpense}>
                            <tr>
                                <td colSpan="3">
                                    <ExpensePeople />
                                </td>
                            </tr>
                        </DisplayIf>
                    </React.Fragment>
                );
            });
        }
    };

    return (
        <div className="col-xl-12 col-lg-12">
            <div className="card shadow mb-4">
                <div
                    className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h6 className="m-0 font-weight-bold text-primary">Expenses</h6>
                    <DisplayIf cond={isAddExpenseEnabled}>
                        <Button onClick={handleAddExpenseButton}><i className="fas fa-fw fa-plus"></i></Button>
                    </DisplayIf>
                    <DisplayIf cond={!isAddExpenseEnabled}>
                        (Add people before adding expenses)
                    </DisplayIf>
                </div>
                <div className="card-body">
                    <table className="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th className="table-fit">Payer</th>
                            <th className="table-fit">Consumers</th>
                            <th className="table-fit">Amount</th>
                        </tr>
                        </thead>
                        <tbody>
                        <ExpenseRows />
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}
