import {removeAuthUser} from "./authUser";
import {Status401Error} from "./crud";

function compareName(a, b) {
    return a.name.localeCompare(b.name);
}

export function restBad(error, navigate) {
    if (error instanceof Status401Error) {
        removeAuthUser();
        navigate('/');
    } else {
        navigate(`/error?error=${error}`);
    }
}

function valid(project) {
    if (project.ProjectExpenses.length === 0) {
        return false;
    }

    let valid = true;
    project.ProjectExpenses.forEach(pe => {
        if (!project.ProjectExpenseDists.find(ped => ped.ProjectExpenseId === pe.id)) {
            valid = false;
        }
    });
    return valid;
}

export { compareName , valid };
