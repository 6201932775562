import {Navigate, useLocation} from "react-router-dom";
import {getAuthUser} from "../helpers/authUser";

export default function RequireAuth({ children }) {
    const location = useLocation();

    const user = getAuthUser();
    if (user) {
        return children;
    }

    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
}
