import React, {useState} from "react";
import useBodyClass from "../helpers/useBodyClass";
import {Link} from "react-router-dom";
import {useForm} from "react-hook-form";
import * as CRUD from "../helpers/crud";
import Spinner from "../components/Spinner";
import DisplayIf from "../components/DisplayIf";

export default function Register() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [registered, setRegistered] = useState(false);
    const { register, handleSubmit, formState: { errors }, getValues } = useForm();
    const handleError = (errors) => {};

    useBodyClass('bg-gradient-primary');

    const handleRegister = (data) => {
        setLoading(true);

        CRUD.register(data)
            .then((response) => {
                    if (response.ok) {
                        setRegistered(true);
                    } else {
                        setError('Error registering');
                    }
                },
                (error) => {
                    setError(error);
                }
            )
            .finally(() => setLoading(false));
    };

    const isMatchingPassword = () => {
        return getValues("password") === getValues("password2");
    };

    const registerOptions = {
        firstName: { required: "First Name is required" },
        lastName: { required: "Last Name is required" },
        email: { required: "Email is required" },
        password: { required: "Password is required", minLength: { value: 8, message: "Must be 8 or more characters" } },
        password2: { required: "Repeat Password is required", minLength: { value: 8, message: "Must be 8 or more characters" },
            validate: isMatchingPassword }
    };

    const RegisterForm = () => {
        return (
            <div className="col-lg-7">
                <div className="p-5">
                    <div className="text-center">
                        <h1 className="h4 text-gray-900 mb-4">Create an Account!</h1>
                    </div>
                    <form className="user" onSubmit={handleSubmit(handleRegister, handleError)}>
                        <div className="form-group row">
                            <div className="col-sm-6 mb-3 mb-sm-0">
                                <input type="text" className="form-control form-control-user"
                                       id="exampleFirstName"
                                       placeholder="First Name"
                                       {...register('firstName', registerOptions.firstName) } />
                                <small className="text-danger">
                                    {errors?.firstName && errors.firstName.message}
                                </small>
                            </div>
                            <div className="col-sm-6">
                                <input type="text" className="form-control form-control-user"
                                       id="exampleLastName"
                                       placeholder="Last Name"
                                       {...register('lastName', registerOptions.lastName) } />
                                <small className="text-danger">
                                    {errors?.lastName && errors.lastName.message}
                                </small>
                            </div>
                        </div>
                        <div className="form-group">
                            <input type="email" className="form-control form-control-user"
                                   id="exampleInputEmail"
                                   placeholder="Email Address"
                                   {...register('email', registerOptions.email) } />
                            <small className="text-danger">
                                {errors?.email && errors.email.message}
                            </small>
                        </div>
                        <div className="form-group row">
                            <div className="col-sm-6 mb-3 mb-sm-0">
                                <input type="password" className="form-control form-control-user"
                                       id="exampleInputPassword" placeholder="Password"
                                       {...register('password', registerOptions.password) } />
                                <small className="text-danger">
                                    {errors?.password && errors.password.message}
                                </small>
                            </div>
                            <div className="col-sm-6">
                                <input type="password" className="form-control form-control-user"
                                       id="exampleRepeatPassword" placeholder="Repeat Password"
                                       {...register('password2', registerOptions.password2) } />
                                <small className="text-danger">
                                    {errors?.password2 && errors.password2.type === "validate" && (
                                        <div>The two passwords must match.</div>
                                    )}
                                    {errors?.password2 && errors.password2.message}
                                </small>
                            </div>
                        </div>
                        <button className="btn btn-primary btn-user btn-block">
                            Register Account
                        </button>
                    </form>
                    <hr />
                    <div className="text-center">
                        <Link className="small" to="/forgot">Forgot Password?</Link>
                    </div>
                    <div className="text-center">
                        <Link className="small" to="/login">Already have an account? Login!</Link>
                    </div>
                </div>
            </div>
        );
    }
    return (
        <div className="container">
            <Spinner cond={loading} />
            <div className="card o-hidden border-0 shadow-lg my-5">
                <div className="card-body p-0">
                    <div className="row">
                        <div className="col-lg-5 d-none d-lg-block bg-register-image"></div>
                        <DisplayIf cond={!error && !registered}>
                            <RegisterForm />
                        </DisplayIf>
                        <DisplayIf cond={error}>
                            <div className="col-lg-7">
                                <div className="p-5">
                                    <div className="text-center">
                                        <h1 className="h4 text-gray-900 mb-4">There was an error.</h1>
                                        <h5 className="text-gray-900 mb-4">Contact the administrator.</h5>
                                    </div>
                                </div>
                            </div>
                        </DisplayIf>
                        <DisplayIf cond={registered}>
                            <div className="col-lg-7">
                                <div className="p-5">
                                    <div className="text-center">
                                        <h1 className="h4 text-gray-900 mb-4">You have been registered.</h1>
                                        <h5 className="text-gray-900 mb-4">The administrator will contact you when your account is created.</h5>
                                    </div>
                                </div>
                            </div>
                        </DisplayIf>
                    </div>
                </div>
            </div>
        </div>
    );
}
