import React from "react";
import {
    BrowserRouter as Router,
    Route, Routes
} from "react-router-dom";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Home from "./pages/Home";
import Project from "./pages/Project";
import RequireAuth from "./components/RequireAuth";
import Logout from "./pages/Logout";
import Error404 from "./pages/Error404";
import Error from "./pages/Error";
import Share from "./pages/Share";
import Admin from "./pages/Admin";
import Profile from "./pages/Profile";
import RequireAdmin from "./components/RequireAdmin";

export default function App() {
    return (
      <Router>
          <Routes>
              <Route path="/share/:code" element={ <Share /> } />
              <Route path="/login" element={ <Login /> } />
              <Route path="/logout" element={ <Logout /> } />
              <Route path="/register" element={ <Register /> } />
              <Route path="/project/:id" element={ <RequireAuth><Project /></RequireAuth> } />
              <Route path="/admin" element={ <RequireAdmin><Admin /></RequireAdmin> } />
              <Route path="/profile" element={ <RequireAuth><Profile /></RequireAuth> } />
              <Route path="/" element={ <RequireAuth><Home /></RequireAuth> } />
              <Route path="/error" element={ <Error /> } />
              <Route path="*" element={<Error404 />} />
          </Routes>
      </Router>
    );
}
